import Imgproxy from 'imgproxy';
import { GravityType, ResizingType } from 'imgproxy/dist/types';

// NOTE: Used client/server separation approach due to
// process can be enabled on client and
// that way of checking typeof process !== 'undefined' is not working

const serverImageProxyAPI = new Imgproxy({
  baseUrl: process.env.NEXT_PUBLIC_IMGPROXY_URL || '',
  key: process.env.NEXT_PUBLIC_IMGPROXY_KEY || '',
  salt: process.env.NEXT_PUBLIC_IMGPROXY_SALT || '',
  encode: true,
});

const clientImageProxyAPI = new Imgproxy({
  baseUrl: import.meta.env?.VITE_IMGPROXY_URL || '',
  key: import.meta.env?.VITE_IMGPROXY_KEY || '',
  salt: import.meta.env?.VITE_IMGPROXY_SALT || '',
  encode: true,
});
const serverBucketEnv = process.env.NEXT_PUBLIC_IMGPROXY_BASKET || '';
const clientBucketEnv = import.meta.env?.VITE_IMGPROXY_BASKET || '';

export interface GenerateUrlProps {
  url: string;
  width?: number;
  height?: number;
  resizeType: ResizingType;
  dpr?: number;
  quality: number;
  format: string;
  isClient?: boolean;
}

const generateUrl = ({
  url,
  width,
  height,
  resizeType,
  dpr = 1,
  quality,
  format,
  isClient,
}: GenerateUrlProps): string => {
  if (!url) {
    return '';
  }

  if (url.startsWith('http')) {
    return url;
  }

  const proxy = isClient ? clientImageProxyAPI : serverImageProxyAPI;
  const bucket = isClient ? clientBucketEnv : serverBucketEnv;

  return proxy
    .builder()
    .resize(resizeType, width, height, false)
    .dpr(dpr)
    .gravity(GravityType.center)
    .format(format)
    .quality(quality)
    .generateUrl(`${bucket}${url}`);
};

export default generateUrl;

export const createProxiedUrlForImage = (
  originalUrl: string,
  { isClient }: { isClient?: boolean } = {},
) =>
  generateUrl({
    url: originalUrl,
    resizeType: 'fill',
    quality: 90,
    format: 'webp',
    isClient,
  });
