'use client';

import { StaticImageData } from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { eye1, eye2, eye3, image404, image500 } from '@shared/assets/images/scope/errorPage';
import { ERROR_PAGE_CODE, ErrorPageCodeValue } from '@shared/constants/errorCodes';

import ImageProxy from '../ImageProxy/ImageProxy';

export interface ErrorPageProps {
  code?: ErrorPageCodeValue;
  error?: Error;
  reset?: () => void;
}

const ERROR_DETAILS: Record<
  ErrorPageCodeValue,
  {
    image?: StaticImageData | string;
    title: string;
  }
> = {
  [ERROR_PAGE_CODE.NOT_FOUND]: {
    image: image404,
    title: 'The page you are looking for can not be found.',
  },
  [ERROR_PAGE_CODE.ERROR]: {
    image: image500,
    title: 'Something went wrong on the server. Please try again later',
  },
};

export default function ErrorPage({ code = 404 }: ErrorPageProps) {
  const pathname = usePathname();
  const { image, title } = ERROR_DETAILS[code];

  return (
    <main className="flex-auto p-4 w-full flex flex-col justify-center items-center flex-grow min-h-[80vh]">
      {image && (
        <div className="w-9/10 max-w-[1200px] max-h-[450px] relative mb-[45px]">
          <ImageProxy src={image} alt="" className="w-full h-full object-cover relative z-[1]" />

          <div className="absolute max-w-[13%] max-h-[13%] top-[10%] left-[2%]">
            <ImageProxy src={eye1} alt="" className="w-full h-full object-cover" />
          </div>

          <div className="absolute max-w-[13%] max-h-[13%] top-[31%] left-[38%]">
            <ImageProxy src={eye2} alt="" className="w-full h-full object-cover" />
          </div>

          <div className="absolute max-w-[13%] max-h-[13%] top-0 right-[-1%]">
            <ImageProxy src={eye3} alt="" className="w-full h-full object-cover" />
          </div>
        </div>
      )}

      <h2 className="mb-[45px] text-center font-bold text-[27px]">{title}</h2>

      <div className="flex items-center flex-col gap-3">
        <button
          onClick={() => window.location.reload()}
          className="button min-w-64 button-solid-primary button-lg"
        >
          Refresh Page
        </button>

        {pathname !== '/' && (
          <>
            <span>or</span>
            <Link hrefLang="en" href="/" className="button button-outline-primary button-lg">
              Back to Homepage
            </Link>
          </>
        )}
      </div>
    </main>
  );
}
