import Image, { ImageProps } from 'next/image';

import { ResizingType } from 'imgproxy/dist/types';

import { defaultImageSrc } from '@shared/assets/images';
import useImageProxy from '@shared/hooks/useImageProxy';

export interface ImageProxyProps extends Omit<ImageProps, 'src' | 'width' | 'height'> {
  resizeType?: ResizingType;
  format?: 'webp' | 'png' | 'jpg';
  src?: ImageProps['src'] | null;
  width?: number | number[];
  height?: number | number[];
}

const ImageProxy = ({
  width: originalWidth,
  height: originalHeight,
  resizeType = 'fill',
  format = 'webp',
  src: imgSrc,
  alt = '',
  title = '',
  ...props
}: ImageProxyProps) => {
  const { src, hasLoader, loader, width, height, setSrc } = useImageProxy({
    width: originalWidth,
    height: originalHeight,
    resizeType,
    format,
    src: imgSrc,
  });

  return (
    <Image
      onError={() => {
        // prevents infinite errors when default image is already set
        if (src !== defaultImageSrc) {
          setSrc(defaultImageSrc);
        }
      }}
      alt={alt}
      src={src}
      loader={hasLoader ? loader : undefined}
      width={width}
      height={height}
      title={title || alt}
      {...props}
    />
  );
};

export default ImageProxy;
