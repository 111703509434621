import { ImageLoaderProps, ImageProps } from 'next/image';

import { useCallback, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import { ResizingType } from 'imgproxy/dist/types';

import { defaultImageSrc } from '../assets/images';
import generateUrl from '../helpers/imageProxy';

export interface ImageProxyProps extends Omit<ImageProps, 'src' | 'width' | 'height'> {
  resizeType?: ResizingType;
  format?: 'webp' | 'png' | 'jpg';
  src?: ImageProps['src'] | null;
  width?: number | number[];
  height?: number | number[];
  isClient?: boolean;
}

const aspectRatioHeight = (originalWidth: number, originalHeight: number, width: number): number =>
  Math.round((originalHeight / originalWidth) * width);

const getAppropriateSize = ({
  size,
  isTablet: isTab,
  isMobile: isMob,
}: {
  size?: number | number[];
  isTablet?: boolean;
  isMobile?: boolean;
} = {}): number | undefined => {
  if (typeof size === 'number' || typeof size === 'undefined') {
    return size;
  }

  if (isMob && size[2]) {
    return size[2];
  }

  if (isTab && size[1]) {
    return size[1];
  }

  return size[0];
};

const useImageProxy = ({
  width: originalWidth,
  height: originalHeight,
  resizeType = 'fill',
  format = 'webp',
  src: imgSrc,
  isClient,
}: Pick<ImageProxyProps, 'width' | 'height' | 'resizeType' | 'format' | 'src' | 'isClient'>) => {
  const [src, setSrc] = useState<ImageProps['src'] | typeof defaultImageSrc>(
    imgSrc || defaultImageSrc,
  );

  // Needed to change image based on new incoming props
  // AND maintain onError logic
  // e.g. change user avatar and all places listen to that change
  useEffect(() => {
    if (!!imgSrc && src !== imgSrc) {
      setSrc(imgSrc);
    }
    // eslint-disable-next-line
  }, [imgSrc]);

  const width = getAppropriateSize({ size: originalWidth, isMobile, isTablet });
  const height = getAppropriateSize({ size: originalHeight, isMobile, isTablet });
  const loader = useCallback(
    ({ src: url, width: loaderWidth, quality = 60 }: ImageLoaderProps) => {
      const loaderHeight =
        width && height ? aspectRatioHeight(Number(width), Number(height), loaderWidth) : undefined;

      return generateUrl({
        url,
        width: loaderWidth,
        height: loaderHeight,
        resizeType,
        quality,
        format,
        isClient,
      });
    },
    [width, height, resizeType, format],
  );
  const hasLoader = !src || (typeof src === 'string' && !/^(?:http|data)/.test(src));

  return {
    hasLoader,
    loader,
    width,
    height,
    resizeType,
    format,
    src,
    setSrc,
  };
};

export default useImageProxy;
